import Vue from 'vue';
import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
import moment from 'moment';
import get_uuid from 'nllib/src/common/get_uuid';
import wait_util from 'nllib/src/common/wait_util';

import HelloWorld from '@/component/hello-world'

export default {
    components: {},
    data() {
        return {
            dynamically_create_component_container: {
                id: get_uuid(),
            },
        };
    },
    methods: {},
    created() {
        // const __this = this;
    },
    mounted() {
        const __this = this;
        const dynamically_create_component_container = __this.dynamically_create_component_container;
        const id = dynamically_create_component_container.id;
        //不要使用id或者class
        //否则有可能会报document.querySelector(selector)
        //【Failed to execute 'querySelector' on Document in JS】
        // const selector = `#${id}`;
        // const selector = `.${id}`;
        const selector = `[data-id="${id}"]`;
        console.log(selector);

        const aHelloWorld = new (Vue.extend(HelloWorld))({
            propsData: {
                remarks: `好好学习，天天向上`,
            },
            methods: {
                say_hello_world() {
                    console.log(`hello world!`);
                },
            },
        });
        aHelloWorld.$on(`custom_event`, (data) => {
            console.log(`custom_event`, data);
        });

        Q.when()
            .then(function () {
                return wait_util({
                    enable_log: true,
                    check() {
                        const $e = document.querySelector(selector);

                        const flag = !!$e;

                        return Q.resolve({
                            flag,
                        });
                    },
                });
            })
            .then(function () {
                aHelloWorld.$mount(selector);
            })
            .catch(function (ex) {
                if (!ex) return Q.reject(ex);
                const enable_log_error = !ex.disable_log_error;
                if (!enable_log_error) return Q.reject(ex);
                console.error(ex);
                console.trace();
                if (ex.stack) console.error(ex.stack);
                return Q.reject(ex);
            })
        ;
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}

// 参考
// https://www.jianshu.com/p/a63fbea48b7b
// https://cn.vuejs.org/v2/api/?#propsData
