import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
import moment from 'moment';

export default {
    props: {
        remarks: {
            type: String,
            required: false,
            default: `(空)`,
        },
    },
    data() {
        return {};
    },
    methods: {

        __btn_call_method_click_event_handler() {
            const __this = this;
            __this.say_hello_world();
        },

        __btn_emit_event_click_event_handler() {
            const __this = this;
            __this.$emit(`custom_event`, {
                date_time: moment().format(`YYYY-MM-DD HH:mm:ss`),
            });
        },
    },
}
